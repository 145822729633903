import { AuthField } from "./authField"

type ValidationFunction = (value: string) => PromiseLike<string | undefined>

export function validateFieldWith(field: AuthField, validationFunction: ValidationFunction) {
    async function validate(value: string) {
        const error = await validationFunction(value)
        field.setValidationResult(error)
    }

    field.registerInlineValidationListener(() => {
        validate(field.value).catch((e: unknown) => {
            console.error(e)
        })
    })
}
